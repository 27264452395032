import React, { useEffect, useState } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import {
  Button,
  Form,
  Grid,
  Header,
  Icon,
  Message,
  Table,
} from "semantic-ui-react";
import { Link, useHistory } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import { listUsers } from "../../graphql/queries";
import countries from "../../constants/countries";
import { State, City } from "country-state-city";

import "./styles.css";

const moment = require("moment");

const Users = ({ searchValue, selectedCityValue }) => {
  const history = useHistory();
  const getPagingData = { tokens: [], prev: null, next: null };
  const defaultPageSize = 100;

  const [sorting, setSorting] = useState(null);
  const [useIndex, setUseIndex] = useState(null);
  const [scanIndexForward, setScanIndexForward] = useState(null);
  const [paging, setPaging] = useState(getPagingData);

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group>
        {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                tokens,
                prev: null,
                next: null,
              });
              // setCntLoading(true);
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )}
        {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
              // setCntLoading(true);
            }}
            disabled={!paging.next}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )}

        <Button
          icon
          labelPosition="right"
          onClick={() => {
            tokens.push(nextToken);
            setPaging({
              tokens,
              prev: paging.next,
              next: nextToken,
            });
            // setCntLoading(true);
            history.push({
              pathname: `${window.location.pathname}`,
              search: "",
            });
          }}
          disabled={nextToken === paging.next || !nextToken}
        >
          Next
          <Icon name="right arrow" />
        </Button>
      </Button.Group>
    );
  };

  const Sorting = (col) => {
    if (col === "responses") {
      if (sorting === "responses-asc") {
        setSorting("responses-desc");
        setUseIndex("userType-responses-index");
        setScanIndexForward(true);
      } else if (sorting === "responses-desc") {
        setSorting(false);
        setUseIndex(false);
        setScanIndexForward(false);
      } else {
        setSorting("responses-asc");
        setUseIndex("userType-responses-index");
        setScanIndexForward(false);
      }
    }
  };

  const TableView = ({ users, nextToken }) => (
    <div className="customer-list-table">
      <Paging nextToken={nextToken} tokens={paging.tokens} />
      <Table basic="very" celled collapsing>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Phone</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>Maids Contacted</Table.HeaderCell>
            <Table.HeaderCell
              onClick={() => {
                Sorting("responses");
              }}
              className="sortableHeaderCell"
            >
              <div>
                <div>Responded</div>
                <div className="aligner padLeft">
                  {sorting === "responses-asc" ? (
                    <Icon name="angle down" color="black" className="aligned" />
                  ) : null}
                  {sorting === "responses-desc" ? (
                    <Icon name="angle up" color="black" className="aligned" />
                  ) : null}
                </div>
              </div>
            </Table.HeaderCell>
            <Table.HeaderCell>Date Registered</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <RowContent users={users} />
      </Table>
      <Paging nextToken={nextToken} tokens={paging.tokens} />
    </div>
  );

  const RowContent = ({ users }) => (
    <Table.Body>
      {users.map((user) => {
        const {
          conversations,
          responses = "0",
          firstName,
          lastName = "",
          phone,
          email,
          cognitoId: id,
          dateCreated,
          locale,
        } = user;

        const userConversations = conversations.userConversations.filter(
          (x) => !x.isSupport
        );
        const dateRegistered = moment(dateCreated, "x").format(
          "DD ddd MMM YYYY HH:mm"
        );
        let country = "en-GB";

        if (locale) {
          const matchedCountry = countries.find((c) => c.key === locale);
          country = matchedCountry ? matchedCountry.text : locale;
        }

        return (
          <Table.Row key={id}>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>
                  {firstName} {lastName}
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>{phone}</Table.Cell>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell>{country}</Table.Cell>
            <Table.Cell>{userConversations.length}</Table.Cell>
            <Table.Cell>{responses || "0"}</Table.Cell>
            <Table.Cell>{dateRegistered}</Table.Cell>
            <Table.Cell>
              <Button primary as={Link} to={`/customer/${id}`} target="_blank">
                View
              </Button>
              <Button secondary as={Link} to={`/conversation/${id}?s=1`}>
                Message
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  const query = {
    userType: "Customers",
    limit: defaultPageSize,
    searchText: searchValue,
    searchCity: selectedCityValue,
    nextToken: paging?.next ?? "",
  };

  if (useIndex) {
    query.useIndex = useIndex;
  }

  if (scanIndexForward) {
    query.scanIndexForward = scanIndexForward;
  }

  return (
    <Connect query={graphqlOperation(listUsers, query)}>
      {({ data: { listUsers: users }, loading }) => {
        if (loading)
          return (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Just one second</Message.Header>
                Processing your request.
              </Message.Content>
            </Message>
          );

        return users?.items?.length > 0 ? (
          <>
            <Grid>
              <Grid.Row>
                <Grid.Column>
                  <Header as="h3" color="grey" className="mb-16">
                    Displaying {users?.items?.length} customers
                  </Header>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <TableView users={users?.items} nextToken={users?.nextToken} />
          </>
        ) : (
          <h3 className="text-danger">No matching records found</h3>
        );
      }}
    </Connect>
  );
};

const Customers = () => {
  const [searchText, setSearchText] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [locale, setLocale] = useState("en-GB");
  const [cities, setCities] = useState([]);
  const [displayedCities, setDisplayedCities] = useState([]); // Cities to display in batches
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedCityValue, setSelectedCityValue] = useState("");
  const [batchSize] = useState(500); // Set batch size

  // Function to remove duplicate cities based on the city name
  const removeDuplicates = (cityArray) => {
    const uniqueCities = cityArray.filter(
      (city, index, self) =>
        index === self.findIndex((c) => c.name === city.name)
    );
    return uniqueCities;
  };

  useEffect(() => {
    let countryCode = locale.replace("en-", "");
    if (countryCode) {
      const countryStates = State.getStatesOfCountry(countryCode);
      let allCities = [];

      countryStates.forEach((state) => {
        const stateCities = City.getCitiesOfState(countryCode, state.isoCode);
        // If the state has no cities, use the state name as a fallback.
        if (stateCities.length === 0) {
          allCities.push({ name: state.name });
        } else {
          allCities = [...allCities, ...stateCities];
        }
      });

      // Remove duplicate cities
      const uniqueCities = removeDuplicates(allCities);

      const allCityOptions = uniqueCities.map((city) => ({
        key: city.name,
        text: city.name,
        value: city.name,
      }));
      setCities(allCityOptions);
      // Initially set the first batch of cities
      setDisplayedCities(allCityOptions.slice(0, batchSize));
    }
  }, [locale]);

  // Function to filter and batch cities based on search input
  const handleSearchChange = (e, { searchQuery }) => {
    // Filter cities based on the search query
    const filteredCities = cities.filter((city) =>
      city.text.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Display the first batch of the filtered cities
    setDisplayedCities(filteredCities.slice(0, batchSize));
  };

  return (
    <BaseLayout>
      <Header as="h2">Customers</Header>
      <div className="justify-content-between card-item search-info">
        <Form className="w-100">
          <Form.Input
            label="Search for"
            placeholder="Name, Email, or Phone..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Form.Select
            className="country-filter"
            label="Country"
            placeholder="Select country"
            options={countries}
            value={locale}
            onChange={(event, { value }) => {
              event.preventDefault();
              setLocale(value);
              setSelectedCity(() => (value !== locale ? "" : selectedCity));
            }}
          />
          <Form.Select
            className="city-filter"
            label="City"
            placeholder={"Select city"}
            options={displayedCities} // Use the batched and filtered cities
            value={selectedCity}
            onChange={(event, { value }) => {
              event.preventDefault();
              setSelectedCity(value);
            }}
            onSearchChange={handleSearchChange} // Handle search input
            search
          />
          <Button
            color="green"
            onClick={() => {
              setSearchValue(searchText);
              setSelectedCityValue(selectedCity);
            }}
          >
            Search
          </Button>
          <Button
            color="red"
            onClick={() => {
              setSearchText("");
              setSearchValue("");
              setLocale("en-GB");
              setSelectedCity("");
              setSelectedCityValue("");
            }}
          >
            Reset
          </Button>
        </Form>
      </div>

      <Users searchValue={searchValue} selectedCityValue={selectedCityValue} />
    </BaseLayout>
  );
};

export default withAuthenticator(Customers);
