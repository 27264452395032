import React from 'react';
import { Header, Icon } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';

const FourZeroFour = () => {
	return (
		<BaseLayout>
			<Header as="h1">Page Not Found</Header>
			<Icon name="dont" size="massive" />
		</BaseLayout>
	);
};

export default FourZeroFour;
