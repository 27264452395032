/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConversation = `mutation CreateConversation($createdAt: String, $id: ID!, $name: String!) {
  createConversation(createdAt: $createdAt, id: $id, name: $name) {
    createdAt
    id
    messages {
      nextToken
    }
    name
  }
}
`;

export const createMessage = `mutation CreateMessage(
  $content: String
  $image: String
  $conversationId: ID!
  $createdAt: String!
  $id: ID!
) {
  createMessage(
    content: $content
    image: $image
    conversationId: $conversationId
    createdAt: $createdAt
    id: $id
  ) {
    author {
      firstName
      lastName
      cognitoId
      registered
    }
    content
    image
    conversationId
    createdAt
    id
    isSent
    recipient {
      cognitoId
      registered
    }
    sender
  }
}
`;

export const createUser = `mutation CreateUser($username: String!) {
  createUser(username: $username) {
    cognitoId
    conversations {
      nextToken
    }
    id
    messages {
      nextToken
    }
    username
    registered
  }
}
`;
export const createUserConversations = `mutation CreateUserConversations($conversationId: ID!, $userId: ID!, $isSupport: Boolean) {
  createUserConversations(conversationId: $conversationId, userId: $userId, isSupport: $isSupport) {
    associated {
      conversationId
      userId
      user {
        firstName
        lastName
        cognitoId
      }
    }
    conversation {
      createdAt
      id
      name
      messages {
        content
        createdAt
      }
    }
    conversationId
    user {
      cognitoId
      firstName
      lastName
      registered
    }
    userId
  }
}
`;

export const createAppointmentRequest = `mutation CreateAppointmentRequest($input: CreateAppointmentRequestInput!) {
  createAppointmentRequest(input: $input) {
    id
    customerId
    maidId
    createdAt
  }
}
`;

export const createPushToken = `mutation CreatePushToken($input: CreatePushTokenInput!) {
  createPushToken(input: $input) {
    tokenId
    userId
  }
}
`;

export const updateMaid = `mutation UpdateMaid($input: UpdateMaidInput!) {
  updateMaid(input: $input) {
    hashKey
    rangeKey
    geoJson
    geohash
    hasInsurance
    address
    activeSince
    image
    imageStatus
    availability
    businessType
    dbsCheck
    dateRegistered
    distance
    email
    extraServices
    fullName
    name
    bio
    boost
    ownEquipment
    offerTrial
    rate
    selectedDeep
    status
    userId
    gallery
    dbsFile
  }
}`;

export const addSupportConversation = `mutation CreateMaidSupportConversations($input: CreateMaidSupportConversationsInput!) {
  createMaidSupportConversations(input: $input) {
    id
  }
}`;

export const updateSupportConversation = `mutation UpdateMaidSupportConversations($input: UpdateMaidSupportConversationsInput!) {
  updateMaidSupportConversations(input: $input) {
    id
    responded
  }
}`;

export const updateUser = `mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    cognitoId
    firstName
    lastName
    email
    phone
    dateCreated
  }
}`;
export const updateUserStatus = `mutation UpdateUserStatus($userId: String!, $status: String!) {
  updateUserStatus(userId: $userId, status: $status) {
    userId
    status
    success
    message
  }
}`;

export const addReview = `mutation CreateUsersReviews($maidId: String!, $content: String, $rating: Float, $dateCreated: String!, $userId: String, $authorName: String) {
  createUsersReviews(input: {maidId: $maidId, content: $content, rating: $rating, dateCreated: $dateCreated, userId: $userId, authorName: $authorName}) {
    authorName
    rating
    content
    userId
    id
  }
}`;
export const updateReview = `mutation UpdateUsersReviews($id: ID!, $content: String, $rating: Float, $dateCreated: String!, $userId: String, $authorName: String) {
  updateUsersReviews(input: {id: $id, content: $content, rating: $rating, dateCreated: $dateCreated, userId: $userId, authorName: $authorName}) {
    authorName
    rating
    content
    userId
    id
  }
}`;
export const deleteReview = `mutation DeleteUsersReviews($id: ID!) {
  deleteUsersReviews(input: {id: $id}) {
    id
  }
}`;
export const closeOpenSearches = `mutation UpdateMaidSearches($userId: String!, $searchSerial: Int!, $isClose: Boolean) {
  updateMaidSearches(input: { userId: $userId, searchSerial: $searchSerial, isClose: $isClose }) {
    searchSerial
    userId
  }
}`;