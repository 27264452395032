import { API, graphqlOperation } from 'aws-amplify';

/**
 * @desc Recursively fetch all items in a list query using nextToken
 * @param {Object} pFilter The query object from cda-graphql in use.
 * @param {Object} variables The variables to pass to query.
 * @param {Array} pItems Any preliminary Items already fetched
 * @param {Function} callback Optional callback function to be fired with every batch of items from query iteration.
 * @returns {Array} Array of all items received from queries.
 */
async function fetchItemsNextToken(pGraphQLName, pFilter, pItems) {
	if (pGraphQLName) {
		const { data } = await API.graphql(graphqlOperation(pGraphQLName, pFilter));
		if (!data) {
			return undefined;
		}

		const key = Object.keys(data).find(k => k.startsWith('list'));
		const res = data[key]; // res = { items: [], nextToken: '' }

		pItems.push(...res.items);
 
		if (!res.nextToken || (pFilter.limit && pItems.length >= pFilter.limit)) {
			res.items = pItems;
			console.log('Data Fetched:');
			console.log(res);
			return res;
		}
		else {
			pFilter.nextToken = res.nextToken;
			console.log('Calling  fetchItemsNextToken:');			
			return fetchItemsNextToken(pGraphQLName, pFilter, pItems);
		}
	}

	return undefined;
}

export default fetchItemsNextToken;
