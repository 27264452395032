import React, { useEffect } from "react";
import Amplify, { Auth } from "aws-amplify";
import {
  SignIn,
  ConfirmSignIn,
  VerifyContact,
  RequireNewPassword,
  ForgotPassword,
  AmplifyTheme,
  withAuthenticator,
} from "aws-amplify-react";
import { ApolloProvider } from "react-apollo";
import AWSAppSyncClient, { AUTH_TYPE } from "aws-appsync";
import { Switch, Route } from "react-router-dom";
import config from "./aws-exports";

import Maid from "./pages/Maid";
import Maids from "./pages/Maids";
import Blocked from "./pages/Blocked";
import Customer from "./pages/Customer";
import Customers from "./pages/Customers";
import Prospects from "./pages/Prospects";
import Conversation from "./pages/Conversation";
import SupportChats from "./pages/SupportChats";
import SupportChat from "./pages/SupportChat";
import Home from "./pages/Home";
import FourZeroFour from "./pages/404";
import Header from "./components/molecules/Header";
import MapMaids from "./pages/MapMaids";
import MapProspects from "./pages/MapProspects";
import MonthlyRpt from "./pages/MonthlyRpt";
import Enquiries from "./pages/Enquiries";
import Searches from "./pages/Searches";
import Feedbacks from "./pages/Feedbacks";
import ProfileChanges from "./pages/ProfileChanges";
import PushNotifications from "./pages/PushNotifications";

Amplify.configure(config);

const authTheme = {
  ...AmplifyTheme,
  sectionContainer: {
    ...AmplifyTheme.sectionContainer,
    color: "#96c300",
  },
  sectionHeader: {
    ...AmplifyTheme.sectionHeader,
    color: "#96c300",
    backgroundColor: "#fff",
  },
  formSection: {
    ...AmplifyTheme.formSection,
    backgroundColor: "#fff",
  },
  sectionFooter: {
    ...AmplifyTheme.sectionFooter,
    backgroundColor: "#fff",
  },
  button: {
    ...AmplifyTheme.button,
    backgroundColor: "#96c300",
  },
};

const client = new AWSAppSyncClient({
  url: config.aws_appsync_graphqlEndpoint,
  region: config.aws_appsync_region,
  auth: {
    type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
    jwtToken: async () =>
      (await Auth.currentSession()).getIdToken().getJwtToken(),
  },
  disableOffline: true,
});

function App() {
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {})
      .catch((error) => console.log({ error }));
  });

  return (
    <ApolloProvider client={client}>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/maid/:id" component={Maid} />
        <Route path="/maids" component={Maids} />
        <Route path="/blocked" component={Blocked} />
        <Route path="/customer/:id" component={Customer} />
        <Route path="/customers" component={Customers} />
        <Route path="/enquiries" component={Enquiries} />
        <Route path="/feedbacks" component={Feedbacks} />
        <Route path="/searches" component={Searches} />
        <Route path="/prospects" component={Prospects} />
        <Route path="/conversation/:id" component={Conversation} />
        <Route path="/supportchat/:id" component={SupportChat} />
        <Route path="/supportchats" component={SupportChats} />
        <Route path="/mapmaids" component={MapMaids} />
        <Route path="/mapprospects" component={MapProspects} />
        <Route path="/monthlyrpt1" component={MonthlyRpt} />
        <Route path="/profileChanges" component={ProfileChanges} />
        <Route path="/pushNotifications" component={PushNotifications} />
        <Route path="*" component={FourZeroFour} />
      </Switch>
    </ApolloProvider>
  );
}

export default withAuthenticator(App, {
  includeGreetings: false,
  theme: authTheme,
  authenticatorComponents: [
    <Header />,
    <SignIn />,
    <ConfirmSignIn />,
    <VerifyContact />,
    <ForgotPassword />,
    <RequireNewPassword />,
  ],
});
