/* eslint-disable no-unused-vars */
/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { API_GATEWAY } from '../constants/Urls';

const TIMEOUT = 90000;

export const getMonthlyRpt1 = async (crYear, fromMonth, toMonth, rptMode, accessToken) => {
	const url = `${API_GATEWAY}prod/monthly-report?qpYear=${crYear}&qpFromMonth=${fromMonth}&qpToMonth=${toMonth}&qpMode=${rptMode}`;

	return axios({
		method: 'GET',
		url,
		timeout: TIMEOUT,
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json'
		}
	})
		.then(res => {
			return res.data;
		})
		.catch(error => {
			console.log('Error retrieving report data (getMonthlyRpt1)', error);
			return error;
		});
};
