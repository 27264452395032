import moment from "moment";

export const getLastActive = (lastActiveDate) => {
  const currentDate = Date.now(); // Current date in milliseconds

  const duration = moment.duration(currentDate - lastActiveDate);
  const years = duration.years();
  const months = duration.months();
  const days = duration.days();
  console.log(currentDate, lastActiveDate);
  console.log(currentDate - lastActiveDate);
  let result = "";

  if (years > 0) {
    result += years + " year";
    if (years > 1) result += "s";
    result += " ";
  }
  if (months > 0) {
    result += months + " month";
    if (months > 1) result += "s";
    result += " ";
  }
  if (days > 0) {
    result += days + " day";
    if (days > 1) result += "s";
    result += " ";
  }

  result += "ago";

  return lastActiveDate ? result : "Ancient history";
};

export const NumberWithSeparator = (input) => {
  // Check if the input is a number or a string
  if (typeof input === "number") {
    return input.toLocaleString();
  } else if (typeof input === "string") {
    // Attempt to convert the string to a number
    const parsedNumber = parseFloat(input);

    // Check if the parsed value is a valid number
    if (!isNaN(parsedNumber)) {
      return parsedNumber.toLocaleString();
    } else {
      // If input is an invalid number string, return as is or handle error
      console.warn("Invalid number string:", input);
      return input; // Return original input or handle as needed
    }
  } else {
    // If input is not a string or number, return input or handle as needed
    console.warn("Invalid input type:", typeof input);
    return input;
  }
};
