import React, { useState } from 'react';
import { withAuthenticator, Connect } from 'aws-amplify-react';
import { graphqlOperation } from 'aws-amplify';
import { Header, Label, Table, Grid } from 'semantic-ui-react';
import BaseLayout from '../../components/templates/BaseLayout';
import DropdownList from '../../components/atoms/DropdownList';
import { prospectsByLocale } from '../../graphql/queries';
import countries from '../../constants/countries';

import './styles.css';

const moment = require('moment');

const TableView = ({ prospects }) => (
	<Table basic="very" celled collapsing>
		<Table.Header>
			<Table.Row>
				<Table.HeaderCell>Postcode</Table.HeaderCell>
				<Table.HeaderCell>Number</Table.HeaderCell>
				<Table.HeaderCell>Clean Type</Table.HeaderCell>
				<Table.HeaderCell>Frequency</Table.HeaderCell>
				<Table.HeaderCell>Property</Table.HeaderCell>
				<Table.HeaderCell>Bedroom</Table.HeaderCell>
				<Table.HeaderCell>Bathroom</Table.HeaderCell>
				<Table.HeaderCell>Date Created</Table.HeaderCell>
			</Table.Row>
		</Table.Header>
		<RowContent prospects={prospects} />
	</Table>
);

const RowContent = ({ prospects }) => (
	<Table.Body>
		{prospects.map(prospect => {
			const {
				id,
				postcode,
				number,
				dateCreated,
				geoJson,
				cleanType,
				frequency,
				bathroom,
				bedroom,
				property
			} = prospect;

			const geoParsed = JSON.parse(geoJson);
			const coordinates = `${geoParsed.latitude}%2C${geoParsed.longitude}`;
			const mapLink = `https://maps.google.com/?q=${coordinates}&z=16`;
			return (
				<Table.Row key={id}>
					<Table.Cell>
						<Header as="h4">
							<Header.Content>
								{postcode}
								<Header.Subheader>
									<Label
										as="a"
										href={mapLink}
										target="_blank"
										content="Map"
										icon="map"
										className="mapLink"
									/>
								</Header.Subheader>
							</Header.Content>
						</Header>
					</Table.Cell>
					<Table.Cell>{number}</Table.Cell>
					<Table.Cell>{cleanType}</Table.Cell>
					<Table.Cell>{frequency}</Table.Cell>
					<Table.Cell>{property}</Table.Cell>
					<Table.Cell>{bedroom}</Table.Cell>
					<Table.Cell>{bathroom}</Table.Cell>
					<Table.Cell>
						{moment(dateCreated, 'x').format('DD ddd MMM YYYY HH:mm')}
					</Table.Cell>
				</Table.Row>
			);
		})}
	</Table.Body>
);

const Prospects = () => {
	const [locale, setLocale] = useState('en-GB');

	const AllProspects = () => {
		return (
			<Connect query={graphqlOperation(prospectsByLocale, { locale })}>
				{({ data: { listProspectsByLocale }, loading }) => {
					if (loading || !listProspectsByLocale) return <h3>Loading...</h3>;

					return <TableView prospects={listProspectsByLocale} />;
				}}
			</Connect>
		);
	};

	return (
		<BaseLayout>
			<Grid divided>
				<Grid.Row>
					<Grid.Column>
						<Header as="h2">Prospects</Header>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row>
					<Grid.Column width={6}>
						<Header as="h3" content="Country" />
						<DropdownList
							options={countries}
							value={locale}
							onChange={(event, { value }) => setLocale(value)}
						/>
					</Grid.Column>
				</Grid.Row>
				<Grid.Row></Grid.Row>
			</Grid>
			<AllProspects />
		</BaseLayout>
	);
};

export default withAuthenticator(Prospects);
