import React from 'react';
import { Connect } from 'aws-amplify-react';
import { Grid } from 'semantic-ui-react';
import { graphqlOperation } from 'aws-amplify';
import { getConversationUsers } from '../../../graphql/queries';
import Notifications from '../Notifications';

const AllParticipants = ({ conversationId }) => {
	const Participants = ({ users }) => {
		return (
			<Grid columns={2} divided>
				<Grid.Row>
					{users.map(user => {
						const {
							user: { firstName, lastName, userType, cognitoId }
						} = user;
						return (
							<Grid.Column key={cognitoId} width={3}>
								<h3>{userType === 'Customers' ? 'Client' : 'Maid'}</h3>
								<a
									className="messageLink"
									href={
										userType === 'Customers'
											? `/customer/${cognitoId}`
											: `/maid/${cognitoId}`
									}
								>
									{firstName} {lastName}
								</a>
								<Notifications userId={cognitoId} />
							</Grid.Column>
						);
					})}
				</Grid.Row>
			</Grid>
		);
	};

	return (
		<Connect query={graphqlOperation(getConversationUsers, { conversationId })}>
			{({ data: { getUsersForConversation }, loading }) => {
				if (loading || !getUsersForConversation) return <h3>Loading...</h3>;

				return <Participants users={getUsersForConversation} />;
			}}
		</Connect>
	);
};

const ConversationsParticipants = React.memo(({ conversationId }) => {
	return <AllParticipants conversationId={conversationId} />;
});

export default ConversationsParticipants;
