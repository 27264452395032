import React, { useEffect, useState } from "react";
import { withAuthenticator, Connect } from "aws-amplify-react";
// import { graphqlOperation } from "aws-amplify";
// import {
//   Header,
//   Grid,
//   Statistic,
//   Image,
//   Card,
//   Button,
//   ButtonContent,
//   Icon,
// } from "semantic-ui-react";
import BaseLayout from "../../components/templates/BaseLayout";
// import { countProspects, listDashboard } from "../../graphql/queries";
import { useHistory } from "react-router-dom";
import "./styles.css";
import { API_GATEWAY } from "../../constants/Urls";
import { Auth } from "aws-amplify";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { NumberWithSeparator } from "../../components/molecules/functions";
import { Link } from "react-router-dom";

// const ConversationCount = () => {
//   return (
//     <Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
//       {({ data: { listDashboards: dashboard }, loading }) => {
//         if (loading || !dashboard) return <h3>Loading...</h3>;

//         const totalMaidCustomerChats = dashboard.items.filter(
//           (x) => x.ItemName === "TotalMaidCustomerChats"
//         );

//         return <span>{totalMaidCustomerChats[0].ItemVal}</span>;
//       }}
//     </Connect>
//   );
// };

// const MaidSupportConversationCount = () => {
//   return (
//     <Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
//       {({ data: { listDashboards: dashboard }, loading }) => {
//         if (loading || !dashboard) return <h3>Loading...</h3>;

//         const totalSupportChats = dashboard.items.filter(
//           (x) => x.ItemName === "TotalSupportChats"
//         );

//         return <span>{totalSupportChats[0].ItemVal}</span>;
//       }}
//     </Connect>
//   );
// };

// const MaidCount = () => {
//   const history = useHistory();
//   return (
//     <Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
//       {({ data: { listDashboards: dashboard }, loading }) => {
//         if (loading || !dashboard) return <h3>Loading...</h3>;

//         const totalMaids = dashboard.items.filter(
//           (x) => x.ItemName === "TotalRegisteredMaids"
//         );
//         const pending = dashboard.items.filter(
//           (x) => x.ItemName === "TotalPendingMaids"
//         );
//         const active = dashboard.items.filter(
//           (x) => x.ItemName === "TotalActiveMaids"
//         );
//         const blocked = dashboard.items.filter(
//           (x) => x.ItemName === "TotalBlockedMaids"
//         );

//         return (
//           <>
//             <Grid columns={2} divided>
//               <Grid.Row>
//                 <Grid.Column>
//                   <h2>{totalMaids[0].ItemVal}</h2>
//                   <p>Registered</p>
//                 </Grid.Column>
//                 <Grid.Column
//                   onClick={() => {
//                     history.push({
//                       pathname: `maids/?p1`,
//                       state: { filterby: "ACTIVE" },
//                     });
//                   }}
//                 >
//                   <h2>{active[0].ItemVal}</h2>
//                   <p>Active</p>
//                 </Grid.Column>
//               </Grid.Row>
//               <Grid.Row>
//                 <Grid.Column
//                   onClick={() => {
//                     history.push({
//                       pathname: `maids/?p1`,
//                       state: { filterby: "PENDING" },
//                     });
//                   }}
//                 >
//                   <h2>{pending[0].ItemVal}</h2>
//                   <p>Pending</p>
//                 </Grid.Column>
//                 <Grid.Column
//                   onClick={() => {
//                     history.push({
//                       pathname: `maids/?p1`,
//                       state: { filterby: "BLOCKED" },
//                     });
//                   }}
//                 >
//                   <h2>{blocked[0].ItemVal}</h2>
//                   <p>Blocked</p>
//                 </Grid.Column>
//               </Grid.Row>
//             </Grid>
//             {/*
// 						<Statistic>
// 							<Statistic.Value>{totalMaids[0].ItemVal}</Statistic.Value>
// 							<Statistic.Label>Registered</Statistic.Label>
// 						</Statistic>
// 						<Statistic>
// 							<Statistic.Value>{active[0].ItemVal}</Statistic.Value>
// 							<Statistic.Label>Active</Statistic.Label>
// 						</Statistic>
// 						<Statistic>
// 							<Statistic.Value>{pending[0].ItemVal}</Statistic.Value>
// 							<Statistic.Label>Pending</Statistic.Label>
// 						</Statistic>
// 						<Statistic>
// 							<Statistic.Value>{blocked[0].ItemVal}</Statistic.Value>
// 							<Statistic.Label>Blocked</Statistic.Label>
// 						</Statistic> */}
//           </>
//         );
//       }}
//     </Connect>
//   );
// };

// const CustomerCount = () => {
//   return (
//     <Connect query={graphqlOperation(listDashboard, { limit: 100000 })}>
//       {({ data: { listDashboards: dashboard }, loading }) => {
//         if (loading || !dashboard) return <h3>Loading...</h3>;

//         const totalCustomers = dashboard.items.filter(
//           (x) => x.ItemName === "TotalCustomers"
//         );
//         return <span>{totalCustomers[0].ItemVal}</span>;
//       }}
//     </Connect>
//   );
// };

// const ProspectsCount = () => {
//   return (
//     <Connect query={graphqlOperation(countProspects)}>
//       {({ data, loading }) => {
//         if (loading || !data) return <h3>Loading...</h3>;
//         return <span>{data.listProspects.items.length}</span>;
//       }}
//     </Connect>
//   );
// };

const Home = () => {
  const today = new Date();

  const getFormattedDate = (date) => {
    return date.toLocaleDateString("en-US");
  };

  const defaultFilter = {
    startDate: getFormattedDate(
      new Date(today.getFullYear(), today.getMonth(), 1)
    ),
    endDate: getFormattedDate(today),
  };
  const [dashboardData, setDashboardData] = useState();
  const [payloads, setPayloads] = useState(defaultFilter);
  const [isCardLoading, setIsCardLoading] = useState(false);

  // const getCurrentDate = () => {
  //   const today = new Date();
  //   return today.toLocaleDateString("en-US");
  // };

  const calculateDateRange = (option) => {
    let startDate, endDate;

    switch (option) {
      case "today":
        startDate = endDate = today;
        break;
      case "this-week":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay()); // Start of week (Sunday)
        endDate = today;
        break;
      case "last-7-days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 6);
        endDate = today;
        break;
      case "this-month":
        startDate = new Date(today.getFullYear(), today.getMonth(), 1);
        endDate = today;
        break;
      case "last-30-days":
        startDate = new Date(today);
        startDate.setDate(today.getDate() - 29);
        endDate = today;
        break;
      case "year-to-date":
        startDate = new Date(today.getFullYear(), 0, 1);
        endDate = today;
        break;
      case "last-12-months":
        startDate = new Date(today);
        startDate.setFullYear(today.getFullYear() - 1);
        endDate = today;
        break;
      default:
        startDate = endDate = today;
    }

    return {
      startDate: getFormattedDate(startDate),
      endDate: getFormattedDate(endDate),
    };
  };

  const handleChange = (e) => {
    const selectedValue = e.target.value;
    const newPayload = calculateDateRange(selectedValue);
    setPayloads(newPayload);
  };

  const handlerDashboardData = async () => {
    setIsCardLoading(true);
    const url = `${API_GATEWAY}prod/admin-dashboard-stats`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
      data: payloads,
    })
      .then((res) => {
        if (res.status === 200) {
          setIsCardLoading(false);
          setDashboardData(res?.data);
        }
      })
      .catch((error) => {
        console.log("dashboard-error: ", error);
        setIsCardLoading(false);
      });
  };

  useEffect(() => {
    handlerDashboardData();
  }, [payloads]);

  const renderSkeleton = () => (
    <>
      <div className="stats">
        <div className="stat-item">
          <div className="stat-number">
            <Skeleton width={60} />
          </div>
          <div className="stat-label">
            <Skeleton width={100} />
          </div>
        </div>
      </div>
      <div className="total">
        <Skeleton width={100} />
      </div>
    </>
  );

  const renderNoRecords = () => {
    return <span className="text-danger">No records found.</span>;
  };

  const renderDeltaValue = (value) => {
    return (
      <span className={value >= 0 ? "positive" : value < 0 ? "negative" : ""}>
        {`${value >= 0 ? "+" : ""}${NumberWithSeparator(value)}`}
      </span>
    );
  };

  return (
    <BaseLayout>
      <div className="container">
        <div className="overview">
          <h1>Overview</h1>
          <select className="date-selector" onChange={handleChange}>
            <option value="today">Today</option>
            <option value="this-week">This week</option>
            <option value="last-7-days">Last 7 days</option>
            <option value="this-month" selected>
              This month
            </option>
            <option value="last-30-days">Last 30 days</option>
            <option value="year-to-date">Year to date</option>
            <option value="last-12-months">Last 12 months</option>
          </select>
        </div>
        <div className="grid">
          <Link to="/customers">
            <div className="card">
              <h2>Customers</h2>
              {isCardLoading ? (
                renderSkeleton()
              ) : !dashboardData ? (
                renderNoRecords()
              ) : (
                <>
                  <div className="stats">
                    {/* <div className="stat-item">
                    <div className="stat-number">
                      NumberWithSeparator(123)
                      {renderDeltaValue(0)}
                    </div>
                    <div className="stat-label">Active</div>
                  </div> */}
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(
                          dashboardData?.filteredData?.newUser
                        )}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats?.newUserDeltaCount
                        )}
                      </div>
                      <div className="stat-label">New</div>
                    </div>
                    {/* <div className="stat-item">
                    <div className="stat-number">
                      NumberWithSeparator(78)
                      {renderDeltaValue(0)}
                    </div>
                    <div className="stat-label">Blocked</div>
                  </div> */}
                  </div>
                  <div className="total">
                    <span>
                      {NumberWithSeparator(dashboardData?.TotalCustomers)}
                    </span>{" "}
                    total accounts
                  </div>
                </>
              )}
            </div>
          </Link>
          <Link to="/maids?p=1">
            <div className="card" as={Link}>
              <h2>Maids</h2>

              {isCardLoading ? (
                renderSkeleton()
              ) : !dashboardData ? (
                renderNoRecords()
              ) : (
                <>
                  <div className="stats">
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(dashboardData?.TotalActiveMaids)}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats
                            ?.activeMaidsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">Active</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(
                          dashboardData?.filteredData?.newMaids
                        )}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats?.newMaidsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">New</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(dashboardData?.TotalBlockedMaids)}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats
                            ?.blockedMaidsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">Blocked</div>
                    </div>
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(dashboardData?.TotalPendingMaids)}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats
                            ?.pendingMaidsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">Pending</div>
                    </div>
                  </div>
                  <div className="total">
                    <span>
                      {NumberWithSeparator(dashboardData?.TotalRegisteredMaids)}
                    </span>{" "}
                    total accounts
                  </div>
                </>
              )}
            </div>
          </Link>
          <Link to="/prospects">
            <div className="card">
              <h2>Prospects</h2>
              {isCardLoading ? (
                renderSkeleton()
              ) : !dashboardData ? (
                renderNoRecords()
              ) : (
                <>
                  <div className="stats">
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(
                          dashboardData?.filteredData?.newProspects
                        )}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats
                            ?.newProspectsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">New</div>
                    </div>
                  </div>
                  <div className="total">
                    <span>
                      {NumberWithSeparator(dashboardData?.TotalProspects)}
                    </span>{" "}
                    in total
                  </div>
                </>
              )}
            </div>
          </Link>
          <Link to="/supportchats">
            <div className="card">
              <h2>Support Chats</h2>

              {isCardLoading ? (
                renderSkeleton()
              ) : !dashboardData ? (
                renderNoRecords()
              ) : (
                <>
                  <div className="stats">
                    <div className="stat-item">
                      <div className="stat-number">
                        {NumberWithSeparator(
                          dashboardData?.filteredData?.newSupportChats
                        )}
                        {renderDeltaValue(
                          dashboardData?.dashboardDeltaStats
                            ?.newSupportChatsDeltaCount
                        )}
                      </div>
                      <div className="stat-label">New</div>
                    </div>
                  </div>
                  <div className="total">
                    <span>
                      {NumberWithSeparator(dashboardData?.TotalSupportChats)}
                    </span>{" "}
                    in total
                  </div>
                </>
              )}
            </div>
          </Link>
          <div className="card">
            <h2>Maid-Customer chats</h2>
            {isCardLoading ? (
              renderSkeleton()
            ) : !dashboardData ? (
              renderNoRecords()
            ) : (
              <>
                <div className="stats">
                  <div className="stat-item">
                    <div className="stat-number">
                      {NumberWithSeparator(
                        dashboardData?.filteredData?.newConversations
                      )}
                      {renderDeltaValue(
                        dashboardData?.dashboardDeltaStats
                          ?.newConversationsDeltaCount
                      )}
                    </div>
                    <div className="stat-label">New</div>
                  </div>
                </div>
                <div className="total">
                  <span>
                    {NumberWithSeparator(dashboardData?.TotalMaidCustomerChats)}
                  </span>{" "}
                  in total
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default withAuthenticator(Home);
