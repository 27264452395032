/* eslint-disable no-lonely-if */
/* eslint-disable prefer-template */
/* eslint-disable prefer-destructuring */
import React, { useState, useEffect } from "react";
import { withAuthenticator } from "aws-amplify-react";
import {
  Button,
  Header,
  Image,
  Table,
  Grid,
  Popup,
  Icon,
} from "semantic-ui-react";
import { Link } from "react-router-dom";
import BaseLayout from "../../components/templates/BaseLayout";
import DropdownList from "../../components/atoms/DropdownList";

import "./styles.css";
import { getAppData } from "../../services/AppData";
import SweetAlert from "react-bootstrap-sweetalert";
import { API_GATEWAY } from "../../constants/Urls";
import { Auth } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import ReactPlayer from "react-player";

// Modified By : Sanal
// Modified Date : 20220526
// Subject : Can't filter maids by name
// Commit 2

const ProfileChanges = ({ location, history }) => {
  const pagingFromStorage = localStorage.getItem("paging");
  const statusFromStorage = localStorage.getItem("status");
  const isFirstPage = location.search === "?p=1";
  const imageTypes = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "tiff",
    "tif",
    "bmp",
    "svg",
    "webp",
    "ico",
  ];
  const videoTypes = ["mp4", "avi", "mov", "wmv", "mkv", "flv", "3gp"];
  const pendingIcon = "🕗";
  const approvedIcon = "✅";
  const deniedIcon = "❌";

  const [filterStatus, setStatus] = useState(
    statusFromStorage && isFirstPage ? statusFromStorage : "ALL"
  );
  const [paging, setPaging] = useState(
    pagingFromStorage && !isFirstPage
      ? JSON.parse(pagingFromStorage)
      : { tokens: [], prev: null, next: null }
  );
  const [cntLoading, setCntLoading] = useState(true);
  const [pageData, setPageData] = useState({ items: [], nextToken: {} });
  const [openDenyAction, SetOpenDenyAction] = useState(null);
  const [openApproveAction, SetOpenApproveAction] = useState(null);
  const [openMessageAction, SetOpenMessageAction] = useState(null);
  const [statusType, setStatusType] = useState("PENDING");
  const [denialData, setDenialData] = useState({});
  const [approvalData, setApprovalData] = useState({});
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    handleGetProfileModerationData();
  }, [statusType]);

  const handleGetProfileModerationData = async (obj) => {
    setCntLoading(true);
    const url = `${API_GATEWAY}prod/maids-profile-moderation`;
    const objCurrentSession = await Auth.currentSession();
    const idToken = objCurrentSession.getIdToken();
    const pReqestBody = {
      query: {
        KeyConditionExpression: "#status = :status",
        ExpressionAttributeValues: {
          ":status": statusType,
        },
        ...(obj && obj.viewMore && { ExclusiveStartKey: obj.nextToken }),
        ExpressionAttributeNames: {
          "#status": "status",
        },
        ScanIndexForward: false,
      },
      limit: 15,
      byPage: true,
    };
    return axios({
      method: "POST",
      url,
      timeout: 90000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorizer: idToken.getJwtToken(),
      },
      data: pReqestBody,
    })
      .then((response) => {
        if (response?.status === 200) {
          setCntLoading(false);
          setPageData({
            items: pageData.items.concat(response?.data?.items),
            nextToken: response?.data?.nextToken,
          });
        }
      })
      .catch((error) => {
        setCntLoading(false);
        setPageData({
          items: [],
        });
      });
  };

  const handleSubmit = async (obj) => {
    // setopenMailConfirm(null);
    if (obj.status === "REJECTED" && !obj.reason) {
      setErrorMessage("Please provide a reason for denial.");
    } else {
      const url = `${API_GATEWAY}prod/maids-profile-moderation/status`;
      const objCurrentSession = await Auth.currentSession();
      const idToken = objCurrentSession.getIdToken();
      return axios({
        method: "POST",
        url,
        timeout: 90000,
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorizer: idToken.getJwtToken(),
        },
        data: obj,
      })
        .then((res) => {
          if (res.status === 200) {
            SetOpenDenyAction(null);
            SetOpenApproveAction(null);
            SetOpenMessageAction(
              <SweetAlert
                success
                title="Good job!"
                // onConfirm={() => setopenMailConfirm(null)}
                customButtons={
                  <React.Fragment>
                    <Button primary onClick={() => SetOpenMessageAction(null)}>
                      Ok
                    </Button>
                  </React.Fragment>
                }
              >
                {res.data.message}{" "}
              </SweetAlert>
            );
            // handleGetProfileModerationData({
            //   viewMore: true,
            //   nextToken: pageData.nextToken,
            // });
          }
          return res.data;
        })
        .catch((error) => {
          if (error) {
            SetOpenDenyAction(null);
            SetOpenApproveAction(null);
            return SetOpenMessageAction(
              <SweetAlert
                error
                title="Something went wrong!"
                onConfirm={() => SetOpenMessageAction(null)}
                customButtons={
                  <React.Fragment>
                    <Button primary onClick={() => SetOpenMessageAction(null)}>
                      Ok
                    </Button>
                  </React.Fragment>
                }
              >
                {error?.response?.data?.message}
              </SweetAlert>
            );
          }
          return error;
        });
    }
  };

  // const handleDeniedConfirm = (obj) => {
  //   SetOpenDenyAction(
  //     <SweetAlert
  //       title="Reason for denying the change"
  //       placeholder="Provide your reason here..."
  //       onCancel={() => SetOpenDenyAction(null)}
  //       type={"controlled"}
  //       dependencies={[denialReason]}
  //       // customButtons={
  //       //   <React.Fragment>
  //       //     <Button
  //       //       color="red"
  //       //       onClick={() => handleSubmit({ ...obj, denialReason })}
  //       //     >
  //       //       Deny change
  //       //     </Button>
  //       //   </React.Fragment>
  //       // }
  //     >
  //       {(renderProps) => (
  //         <form>
  //           <textarea
  //             rows="4"
  //             // ref={renderProps.setAutoFocusInputRef}
  //             className="form-control"
  //             // onKeyDown={renderProps.onEnterKeyDownConfirm}
  //             onChange={(e) => setDenialReason(e.target.value)}
  //             placeholder="Write reason..."
  //           />
  //           <button
  //             color="red"
  //             onClick={() => handleSubmit({ ...obj, denialReason })}
  //           >
  //             Deny change
  //           </button>
  //         </form>
  //       )}
  //     </SweetAlert>
  //   );
  // };

  // const handleApproveConfirm = (obj) => {
  //   SetOpenApproveAction(
  //     <SweetAlert
  //       warning
  //       showCancel
  //       title="Are you sure you want to approve?"
  //       // onConfirm={() => handleSendMailFunc()}
  //       onCancel={() => SetOpenApproveAction(null)}
  //       customButtons={
  //         <React.Fragment>
  //           <Button secondary onClick={() => SetOpenApproveAction(null)}>
  //             No
  //           </Button>
  //           <Button
  //             primary
  //             // onClick={() => handleSendMailFunc()}
  //           >
  //             Yes
  //           </Button>
  //         </React.Fragment>
  //       }
  //     >
  //       {/* Mail will be sent only one time */}
  //     </SweetAlert>
  //   );
  // };

  const Paging = ({ nextToken, tokens }) => {
    return (
      <Button.Group>
        {/* {tokens.length > 1 && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.splice(0, tokens.length);
              setPaging({
                tokens,
                prev: null,
                next: null,
              });
              setCntLoading(true);
            }}
          >
            First
            <Icon name="fast backward" />
          </Button>
        )} */}
        {/* {paging.next && (
          <Button
            icon
            labelPosition="left"
            onClick={() => {
              tokens.pop();
              setPaging({
                tokens,
                prev: tokens[tokens.length - 1],
                next: paging.prev,
              });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
              setCntLoading(true);
            }}
          >
            Prev
            <Icon name="left arrow" />
          </Button>
        )} */}
        {nextToken && (
          <Button
            className="btn-view-more"
            icon
            labelPosition="right"
            onClick={() => {
              tokens.push(nextToken);
              setPaging({
                tokens,
                prev: paging.next,
                next: nextToken,
              });
              setCntLoading(true);
              handleGetProfileModerationData({ viewMore: true, nextToken });
              history.push({
                pathname: `${window.location.pathname}`,
                search: "",
              });
            }}
          >
            View More...
            {/* <Icon name="right arrow" /> */}
          </Button>
        )}
      </Button.Group>
    );
  };

  const getFileExtension = (uri) => {
    const parts = uri.split(".");
    const extension = parts[parts.length - 1];
    const isImage = imageTypes.includes(extension);
    const isVideo = videoTypes.includes(extension);
    return {
      isImage,
      isVideo,
      extension,
    };
  };

  // This function could be used to generate a thumbnail image for a video URI
  const generateThumbnail = (videoUri) => {
    // You would implement the logic to generate or fetch the thumbnail image here
    // For simplicity, let's just return a placeholder image URL
    return "placeholderThumbnail.jpg";
  };

  const handleShowStatusIcon = (status) => {
    let statusIcon;
    let statusName;
    switch (status) {
      case "PENDING":
        statusIcon = pendingIcon;
        statusName = "Pending";
        break;
      case "APPROVED":
        statusIcon = approvedIcon;
        statusName = "Approved";
        break;
      case "REJECTED":
        statusIcon = deniedIcon;
        statusName = "Denied";
        break;
      default:
        statusIcon = "";
        statusName = "";
        break;
    }
    return { statusIcon, statusName };
  };

  const handleShowChangeType = (changeType) => {
    let changeTypeName;
    switch (changeType) {
      case "firstName":
        changeTypeName = "First name change";
        break;
      case "lastName":
        changeTypeName = "Last name change";
        break;
      case "bio":
        changeTypeName = "Profile bio change";
        break;
      case "image":
        changeTypeName = "New profile picture";
        break;
      case "gallery":
        changeTypeName = "New job photos";
        break;
      default:
        changeTypeName = "";
        break;
    }
    return changeTypeName;
  };

  const TableView = ({ result }) => {
    const { items: maids = [], nextToken } = result;
    const { tokens } = paging;

    if (maids.length === 0) {
      return <h2>No results found</h2>;
    }
    return (
      <>
        <br />
        {/* <Paging nextToken={nextToken} tokens={tokens} /> */}
        <div className="profile-changes-table">
          <Table basic="very" celled collapsing>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Maid/Company Name</Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Maid Requested</Table.HeaderCell>
                <Table.HeaderCell>Changes</Table.HeaderCell>
                {statusType === "PENDING" && (
                  <Table.HeaderCell>Actions</Table.HeaderCell>
                )}
              </Table.Row>
            </Table.Header>
            <RowContent maids={maids} />
          </Table>
        </div>
        <Paging nextToken={nextToken} tokens={tokens} />
      </>
    );
  };

  const RowContent = ({ maids }) => (
    <Table.Body>
      {maids?.map((maid) => {
        const { statusIcon, statusName } = handleShowStatusIcon(maid?.status);
        return (
          <Table.Row key={maid?.userId}>
            <Table.Cell>
              <Header as="h4" image>
                <Header.Content>
                  <Header as="h3">
                    <Image
                      src={maid?.oldData?.image ?? "/images/logo.jpg"}
                      circular
                    />
                    <div className="company-wrapper">
                      <span className="company-owner">
                        <a
                          className="messageLink"
                          href={`/maid/${maid?.userId}`}
                          target="_blank"
                        >
                          {`${maid?.oldData?.name?.firstName} ${maid?.oldData?.name?.lastName}`}
                        </a>
                      </span>
                      <p className="company-name">
                        {maid?.oldData?.companyName}
                      </p>
                    </div>
                  </Header>
                </Header.Content>
              </Header>
            </Table.Cell>
            <Table.Cell>
              <div>
                <sapn>
                  {statusIcon} {statusName}
                </sapn>
                {maid?.status === "REJECTED" && <p>{`[${maid?.reason}]`}</p>}
              </div>
            </Table.Cell>
            <Table.Cell>{handleShowChangeType(maid?.updatedKey)}</Table.Cell>
            <Table.Cell>
              {moment(maid?.createdAt, "x").format("DD/MM/YYYY HH:mm") || "-"}
            </Table.Cell>
            <Table.Cell>
              {maid?.updatedKey === "firstName" && (
                <div>
                  <p>{`${maid?.oldData?.name?.firstName} → ${maid?.updatedValue}`}</p>
                </div>
              )}
              {maid?.updatedKey === "lastName" && (
                <div>
                  <p>{`${maid?.oldData?.name?.lastName} → ${maid?.updatedValue}`}</p>
                </div>
              )}
              {maid?.updatedKey === "bio" && (
                <div>
                  <p>{maid?.updatedValue}</p>
                </div>
              )}
              {maid?.updatedKey === "image" && (
                <div>
                  <Popup
                    trigger={
                      <Image
                        className="rounded-full"
                        src={maid?.updatedValue}
                        width={50}
                        height={50}
                      />
                    }
                    flowing
                    hoverable
                  >
                    <Image src={maid?.updatedValue} width={300} height={250} />
                  </Popup>
                </div>
              )}
              {maid?.updatedKey === "gallery" && (
                <div className="changes-img">
                  {maid?.updatedValue?.map((value) => (
                    <>
                      {getFileExtension(value?.uri).isVideo && (
                        <Popup
                          trigger={
                            <div className="overlay-video">
                              <Image
                                src="/images/icons/video-icon.png"
                                className="video-icon"
                              />
                              {/* <video width={50} height={50}>
                                <source
                                  src={value?.uri}
                                  type={`video/${
                                    getFileExtension(value?.uri)?.extension
                                  }`}
                                />
                              </video> */}
                            </div>
                          }
                          flowing
                          hoverable
                        >
                          <ReactPlayer
                            url={value?.uri}
                            width={250}
                            height={300}
                            playing={true}
                            controls={true}
                            muted={true}
                          />
                          {/* <video
                            controls
                            autoPlay
                            muted
                            width={300} // Set width to match the image width
                            height={250} // Set height to match the image height
                          >
                            <source
                              src={value?.uri}
                              type={`video/${
                                getFileExtension(value?.uri)?.extension
                              }`}
                            />
                          </video> */}
                        </Popup>
                      )}
                      {getFileExtension(value?.uri).isImage && (
                        <Popup
                          trigger={
                            <Image src={value?.uri} width={50} height={50} />
                          }
                          flowing
                          hoverable
                        >
                          <Image src={value?.uri} width={300} height={250} />
                        </Popup>
                      )}
                    </>
                  ))}
                </div>
              )}
            </Table.Cell>
            {statusType === "PENDING" && (
              <Table.Cell>
                <Button.Group>
                  <Button
                    color="green"
                    //   as={Link}
                    //   to={`/maid/${userId}`}
                    onClick={() => {
                      SetOpenApproveAction(true);
                      setApprovalData({
                        id: maid?.id,
                        createdAt: maid?.createdAt,
                        status: "APPROVED",
                        reason: "",
                      });
                      // handleApproveConfirm();
                    }}
                    // target="_blank"
                  >
                    Approve
                  </Button>
                  <Button
                    color="red"
                    onClick={() => {
                      SetOpenDenyAction(true);
                      setDenialData({
                        id: maid?.id,
                        createdAt: maid?.createdAt,
                      });
                      setErrorMessage("");
                    }}
                    // as={Link} to={`/conversation/${userId}?s=1`}
                  >
                    Deny
                  </Button>
                </Button.Group>
              </Table.Cell>
            )}
          </Table.Row>
        );
      })}
    </Table.Body>
  );

  const MaidList = () => {
    if (cntLoading) {
      return <h3>Loading...</h3>;
    }
    if (pageData.items && pageData.items.length > 0) {
      return (
        <>
          <TableView result={pageData} />
        </>
      );
    }
    return <h3>No matching records found</h3>;
  };

  const statusOption = [
    {
      key: "en-Pending",
      text: "PENDING",
      value: "PENDING",
    },
    // {
    //   key: "en-Completed",
    //   text: "COMPLETED",
    //   value: "COMPLETED",
    // },
    {
      key: "en-Approved",
      text: "APPROVED",
      value: "APPROVED",
    },
    {
      key: "en-Denied",
      text: "DENIED",
      value: "REJECTED",
    },
  ];

  return (
    <BaseLayout>
      <Grid divided className="profile-changes-wrapper">
        <div className="profile-details">
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">Profile Changes</Header>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={3}>
              <Grid.Row className="dropDown status-block">
                <Header as="h3" content="Status" />
                <DropdownList
                  options={statusOption}
                  value={statusType}
                  onChange={(event, { value }) => {
                    event.preventDefault();
                    setStatusType(value);
                    setPageData({ items: [], nextToken: {} });
                  }}
                />
              </Grid.Row>
              <div className="action-block">
                <p className="m-0">{`${approvedIcon} Approved`}</p>
                <p>{`${deniedIcon} Denied`}</p>
              </div>
            </Grid.Column>
          </Grid.Row>
        </div>
      </Grid>
      <MaidList />
      {openDenyAction && (
        <SweetAlert
          title="Reason for denying the change"
          placeholder="Provide your reason here..."
          onCancel={() => SetOpenDenyAction(null)}
          type={"controlled"}
          dependencies={[denialData]}
          customButtons={
            <React.Fragment>
              <Button
                color="red"
                onClick={() => {
                  handleSubmit({
                    id: denialData?.id,
                    createdAt: denialData?.createdAt,
                    status: "REJECTED",
                    reason: denialData.denialReason,
                  });
                }}
              >
                Deny change
              </Button>
            </React.Fragment>
          }
        >
          {(renderProps) => (
            <form>
              <textarea
                rows="4"
                className="form-control"
                onChange={(e) => {
                  setDenialData({
                    ...denialData,
                    denialReason: e.target.value,
                  });
                  e.target.value !== "" && setErrorMessage("");
                }}
                placeholder="Write reason..."
              />
              {errorMessage && (
                <div style={{ color: "red" }} className="error">
                  {errorMessage}
                </div>
              )}
            </form>
          )}
        </SweetAlert>
      )}
      {openApproveAction && (
        <SweetAlert
          warning
          showCancel
          title="Are you sure you want to approve?"
          // onConfirm={() => handleSendMailFunc()}
          onCancel={() => SetOpenApproveAction(null)}
          customButtons={
            <React.Fragment>
              <Button secondary onClick={() => SetOpenApproveAction(null)}>
                No
              </Button>
              <Button primary onClick={() => handleSubmit(approvalData)}>
                Yes
              </Button>
            </React.Fragment>
          }
        >
          {/* Mail will be sent only one time */}
        </SweetAlert>
      )}
      {openMessageAction}
    </BaseLayout>
  );
};

export default withAuthenticator(ProfileChanges);
